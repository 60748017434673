<template>
  <b-container fluid class="px-0">
    <b-tabs>
      <b-tab title="Pano">
        <b-card>
          <b-row>
            <b-col cols="2">
              <span>{{ lang("t_date") }}</span>
              <v-select
                v-model="selected_date_filter_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="display_name"
                :reduce="(item) => item.internal_name"
                :options="date_filter_types"
                @input="date_filter_type_changed"
              />
            </b-col>
            <b-col cols="2">
              <span>{{ lang("t_date") }}</span>
              <flat-pickr
                v-model="filter_values.startDate"
                class="form-control"
                :disabled="selected_date_filter_type != 'between_dates'"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  allowInput: true,
                  time_24hr: true,
                }"
              />
            </b-col>
            <b-col cols="2">
              <span>{{ lang("t_date") }}</span>
              <flat-pickr
                v-model="filter_values.endDate"
                class="form-control"
                :disabled="selected_date_filter_type != 'between_dates'"
                :config="{
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  allowInput: true,
                  time_24hr: true,
                }"
              />
            </b-col>
            <b-col cols="3">
              <span>{{ lang("t_queueGroup") }}</span>
              <v-select
                class="mt-50"
                v-model="selected_queue_group"
                :reduce="(option) => option"
                label="display_name"
                :options="queue_groups"
                :placeholder="lang('t_queueGroup')"
                multiple
                :closeOnSelect="false"
                @input="queue_group_changed"
              >
              </v-select>
            </b-col>
            <b-col cols="3">
              <span>{{ lang("t_queues") }}</span>
              <v-select
                class="mt-50"
                v-model="selected_queues"
                :reduce="(option) => option.internal_name"
                label="display_name"
                value="internal_name"
                :options="queues"
                :placeholder="lang('t_queue')"
                multiple
                :closeOnSelect="false"
                @input="
                  () => {
                    get_live_agent();
                    get_inbound_statistics();
                  }
                "
              >
                <template #list-header>
                  <div class="w-100 mb-50">
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="outline-primary"
                      @click="
                        () => {
                          selected_queues = queues.map((e) => e.internal_name);
                          get_live_agent();
                          get_inbound_statistics();
                        }
                      "
                    >
                      <feather-icon size="14" icon="CheckCircleIcon" />
                      {{ lang("t_selectAll") }}
                    </b-button>
                    <b-button
                      class="w-50"
                      size="sm"
                      variant="outline-danger"
                      @click="
                        () => {
                          selected_queues = [];
                        }
                      "
                    >
                      <feather-icon size="14" icon="XOctagonIcon" />
                      {{ lang("t_cleanSelections") }}
                    </b-button>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-card>

        <b-row class="mt-1 d-flex align-items-stretch">
          <b-col cols="5">
            <b-card no-body class="card-developer-meetup">
              <div class="bg-light-primary border rounded-top text-center">
                <b-row>
                  <b-col
                    class="border-right d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="HeadphonesIcon"
                    ></feather-icon>
                    <span class="ml-1">Temsilci</span>
                  </b-col>
                  <b-col
                    class="border-left d-flex align-items-center justify-content-center"
                  >
                    <feather-icon
                      class="text-primary my-1"
                      size="28"
                      icon="UsersIcon"
                    />
                    <span class="ml-1">Müşteri</span>
                  </b-col>
                </b-row>
              </div>
              <b-card-body>
                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <h2 class="my-0 mx-1">81</h2>
                    <feather-icon
                      class="text-success"
                      icon="ArrowUpIcon"
                    ></feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Konuşma Oranı</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon class="text-danger" icon="ArrowDownIcon">
                    </feather-icon>
                    <h2 class="my-0 mx-1">15</h2>
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <feather-icon size="20" icon="SmileIcon"></feather-icon>

                    <h2 class="my-0 mx-1">56</h2>
                    <feather-icon
                      class="text-success"
                      icon="ArrowUpIcon"
                    ></feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Memnuniyet</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon class="text-success" icon="ArrowUpIcon">
                    </feather-icon>
                    <h2 class="my-0 mx-1">26</h2>
                    <feather-icon size="20" icon="MehIcon"></feather-icon>
                  </b-col>
                </b-row>

                <b-row class="mx-0 mt-1 text-center">
                  <b-col class="d-flex align-items-center justify-content-end">
                    <feather-icon size="20" icon="Volume2Icon"></feather-icon>

                    <h2 class="my-0 mx-1">72</h2>
                    <feather-icon
                      class="text-danger"
                      icon="ArrowDownIcon"
                    ></feather-icon>
                  </b-col>

                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span>Ses Tonu</span>
                  </b-col>
                  <b-col
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon class="text-danger" icon="ArrowDownIcon">
                    </feather-icon>
                    <h2 class="my-0 mx-1">22</h2>
                    <feather-icon size="20" icon="VolumeIcon"></feather-icon>
                  </b-col>
                </b-row>

                <!-- metting header -->
                <!-- <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">THU</h6>
                <h3 class="mb-0">24</h3>
              </div>
              <div class="my-auto">
                <b-card-title class="mb-25"> Developer Meetup </b-card-title>
                <b-card-text class="mb-0">
                  Meet world popular developers
                </b-card-text>
              </div>
            </div> -->
                <!--/ metting header -->

                <!-- media -->
                <!-- <b-media v-for="media in mediaData" :key="media.avatar" no-body>
              <b-media-aside class="mr-1">
                <b-avatar rounded variant="light-primary" size="34">
                  <feather-icon :icon="media.avatar" size="18" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0">
                  {{ media.title }}
                </h6>
                <small>{{ media.subtitle }}</small>
              </b-media-body>
            </b-media> -->
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="7">
            <b-card no-body class="card-statistics">
              <b-card-header>
                <b-card-title>
                  <h4 class="font-weight-bolder mb-0">
                    {{ "Konuşma Detayları" }}
                  </h4></b-card-title
                >
                <!-- <b-card-text class="mr-25 mb-0"> Updated 1 month ago </b-card-text> -->
              </b-card-header>
              <b-card-body class="statistics-body pb-0">
                <b-row>
                  <b-col
                    v-for="item in statisticsItems"
                    :key="item.icon"
                    md="4"
                    sm="6"
                    class="mt-auto mb-2 mb-md-0"
                    :class="item.customClass"
                  >
                    <b-media class="mt-2 mb-75" no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="64" :variant="item.color">
                          <feather-icon size="28" :icon="item.icon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h2 class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </h2>
                        <b-card-text class="font-small-3 mb-0">
                          {{ item.subtitle }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card-actions
              :collapsed="false"
              :title="'Anahtar Kelime Kategorileri'"
              action-collapse
            >
              <b-row class="my-1">
                <b-col>
                  <swiper
                    class="swiper-autoplay"
                    :options="swiperOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  >
                    <swiper-slide
                      v-for="(data, index) in [
                        'Şikayet',
                        'Küfürlü Konuşma',
                        'Abonelik İptal',
                        'Teşekkür',
                        'İltifat',
                        'Argo Konuşma',
                      ]"
                      :key="index"
                    >
                      <b-card
                        class="border rounded-lg mb-0 cursor-pointer"
                        no-body
                      >
                        <b-card-body class="pb-0">
                          <div class="truncate">
                            <h2 class="mb-25 font-weight-bolder">
                              {{ Math.floor(Math.random() * 9001) + 1000 }}
                            </h2>
                            <span>{{ data }}</span>
                          </div>
                        </b-card-body>
                        <vue-apex-charts
                          v-if="chartOptionsComputedData[index]"
                          type="area"
                          height="50"
                          width="100%"
                          :options="chartOptionsComputed"
                          :series="[
                            {
                              name: 'data',
                              data: [76, 35, 78, 11, 42, 64, 86],
                            },
                          ]"
                        />
                      </b-card>
                    </swiper-slide>

                    <div slot="pagination" class="swiper-pagination" />
                  </swiper>
                </b-col>
              </b-row>
            </b-card-actions>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <b-card no-body style="height: 62vh; overflow-y: auto" class="">
              <b-row class="mx-0 mt-1">
                <b-col class="d-flex align-items-center">
                  <h4 class="my-0 pl-1">Kayıtlar</h4>
                </b-col>
                <b-col cols="8">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      placeholder="Konuşma, Müşteri Adı, Telefon vs..."
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-card-body>
                <b-table
                  @row-dblclicked="load_sound"
                  ref="refInvoiceListTable"
                  :items="invoices"
                  responsive
                  hover
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  class="position-relative mt-1"
                >
                  <template #head(invoiceStatus)>
                    <feather-icon icon="TrendingUpIcon" class="mx-auto" />
                  </template>

                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <b-link
                      :to="{
                        name: 'apps-invoice-preview',
                        params: { id: data.item.id },
                      }"
                      class="font-weight-bold"
                    >
                      #{{ data.value }}
                    </b-link>
                  </template>

                  <!-- Column: Invoice Status -->
                  <template #cell(invoiceStatus)="data">
                    <b-avatar
                      :id="`invoice-row-${data.item.id}`"
                      size="32"
                      :variant="`light-primary`"
                    >
                      <feather-icon :icon="'UserIcon'" />
                    </b-avatar>
                    <!-- <b-tooltip
                :target="`invoice-row-${data.item.id}`"
                placement="top"
              >
                <p class="">
                  {{ data.item.invoiceStatus }}
                </p>
              </b-tooltip> -->
                  </template>

                  <!-- Column: Client -->
                  <template #cell(client)="data">
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="32"
                          :src="data.item.avatar"
                          :text="data.item.client.name"
                          :variant="`light-primary`"
                        />
                      </template>
                      <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.client.name }}
                      </span>
                      <small class="text-muted">{{
                        data.item.client.companyEmail
                      }}</small>
                    </b-media>
                  </template>

                  <!-- Column: Issued Date -->
                  <template #cell(issuedDate)="data">
                    <span class="text-nowrap">
                      {{ data.value }}
                    </span>
                  </template>

                  <!-- Column: Balance -->
                  <template #cell(balance)="data">
                    <template v-if="data.value === 0">
                      <b-badge pill variant="light-success"> Paid </b-badge>
                    </template>
                    <template v-else>
                      {{ data.value }}
                    </template>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <div class="text-nowrap">
                      <feather-icon
                        :id="`invoice-row-${data.item.id}-send-icon`"
                        icon="SendIcon"
                        class="cursor-pointer"
                        size="16"
                      />
                      <!-- <b-tooltip
                  title="Send Invoice"
                  class="cursor-pointer"
                  :target="`invoice-row-${data.item.id}-send-icon`"
                /> -->

                      <feather-icon
                        :id="`invoice-row-${data.item.id}-preview-icon`"
                        icon="EyeIcon"
                        size="16"
                        class="mx-1"
                        @click="
                          $router.push({
                            name: 'apps-invoice-preview',
                            params: { id: data.item.id },
                          })
                        "
                      />
                      <!-- <b-tooltip
                  title="Preview Invoice"
                  :target="`invoice-row-${data.item.id}-preview-icon`"
                /> -->

                      <!-- Dropdown -->
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon icon="DownloadIcon" />
                          <span class="align-middle ml-50">Download</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          :to="{
                            name: 'apps-invoice-edit',
                            params: { id: data.item.id },
                          }"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="CopyIcon" />
                          <span class="align-middle ml-50">Duplicate</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="test" cols="6">
            <b-card
              style="height: 62vh; overflow-y: auto"
              no-body
              class="mb-0 border-bottom"
            >
              <div>
                <b-row class="border-bottom mx-0">
                  <b-col>
                    <b-row align-h="between" align-v="center">
                      <b-col>
                        <b-avatar
                          size="38"
                          variant="light-secondary"
                          class="my-1"
                        >
                          {{ "B" }}
                        </b-avatar>
                        <span class="ml-1 font-weight-bold">{{
                          "Burak Ersever"
                        }}</span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <div>
                <!-- <div style="height: 70vh; overflow-y: auto"> -->
                <div ref="msg_div" class="messages mt-1">
                  <div v-for="(message, i) in messages" :key="i">
                    <b-row class="px-1">
                      <b-col>
                        <span
                          v-if="message.type != 'note'"
                          :class="
                            message.customer_id
                              ? 'msg_from bg-light-secondary'
                              : 'msg_to bg-light-primary'
                          "
                        >
                          {{ message.body }}
                          <small class="time">
                            {{
                              new Date(message.created_at).toLocaleTimeString(
                                "tr-TR",
                                (options = {
                                  hour: "numeric",
                                  minute: "2-digit",
                                })
                              )
                            }}
                          </small>
                        </span>
                        <span class="msg_note bg-light-warning" v-else>
                          {{ message.body }}
                          <small class="time">
                            {{
                              new Date(message.created_at).toLocaleTimeString(
                                "tr-TR",
                                (options = {
                                  hour: "numeric",
                                  minute: "2-digit",
                                })
                              )
                            }}
                          </small>
                        </span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          :class="test ? 'd-block' : 'd-none'"
          style="position: sticky; bottom: 0; z-index: 999"
        >
          <b-col>
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-dropdown
                      class="mr-50"
                      text="1x"
                      variant="flat-secondary"
                    >
                      <b-dropdown-item> 0.25x </b-dropdown-item>
                      <b-dropdown-item> 0.5x </b-dropdown-item>
                      <b-dropdown-item> 0.75x </b-dropdown-item>
                      <b-dropdown-item> 1x </b-dropdown-item>
                      <b-dropdown-item> 1.25x </b-dropdown-item>
                      <b-dropdown-item> 1.50x </b-dropdown-item>
                      <b-dropdown-item> 1.75x </b-dropdown-item>
                      <b-dropdown-item> 2x </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipBackIcon" />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="btn-icon rounded-circle mx-50"
                      @click="
                        () => {
                          wavesurfer.playPause();
                        }
                      "
                    >
                      <feather-icon
                        v-if="wavesurfer"
                        size="20"
                        :icon="
                          wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'
                        "
                      />
                    </b-button>
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="20" icon="SkipForwardIcon" />
                    </b-button>
                    <span class="ml-50">
                      {{ `${parseFloat(currentTime).toFixed(2)}` }}
                    </span>
                    <b-col id="waveform"></b-col>
                    <span v-if="wavesurfer && wavesurfer.duration">
                      {{ `${wavesurfer.duration}` }}
                    </span>
                  </b-col>
                </b-row>

                <!-- <div id="hover"></div> -->
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab disabled title="Trend Analizi"></b-tab>
      <b-tab disabled title="Fark Analizi"></b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import {
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  VBTooltip,
  BTable,
  BButtonGroup,
  BFormInput,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardHeader,
  BForm,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormRating,
  BInputGroup,
  BInputGroupPrepend,
  BImg,
  BTabs, BTab,
  BAvatarGroup,


} from "bootstrap-vue";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

import ChartjsComponentLineChart from "./Components/ChartjsComponentLineChart.vue";
import BCardActions from "@core/components/b-card-actions/BCardActionsCallAnalize.vue";
import 'swiper/css/swiper.css'
import { $themeColors } from "@themeConfig";
import WaveSurfer from 'wavesurfer.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'
// import RegionsPlugin from 'https://unpkg.com/wavesurfer.js@beta/dist/plugins/regions.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@beta'
console.log("WaveSurfer", WaveSurfer);
// const canvas = document.createElement('canvas')
// const ctx = canvas.getContext('2d')





const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",

    // series1: '#60f2ca',
    // series2: '#ffe700',

    // series3: '#FFA1A1',
  },
};
export default {
  components: {
    flatPickr,
    BTabs, BTab,
    BImg,
    BInputGroupPrepend,
    BAvatarGroup,
    BProgress,
    Swiper,
    SwiperSlide,
    BInputGroup,
    BLink,
    BDropdown,
    BDropdownItem,
    BForm,
    BCardActions,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    VBTooltip,
    BTable,
    BButtonGroup,
    BFormInput,
    VueApexCharts,
    BContainer,
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
    BFormRating,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      mediaData: [
        { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
        { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
      ],
      avatars: [
        { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
        { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
      ],
      date_filter_types: [
        {
          internal_name: "today",
          display_name: globalThis._lang("t_today"),
        },
        {
          internal_name: "yesterday",
          display_name: globalThis._lang("t_yesterday"),
        },
        {
          internal_name: "last_7_days",
          display_name: globalThis._lang("t_last7Days"),
        },
        {
          internal_name: "last_30_days",
          display_name: globalThis._lang("t_last30Days"),
        },
        {
          internal_name: "this_month",
          display_name: globalThis._lang("t_thisMonth"),
        },
        {
          internal_name: "last_month",
          display_name: globalThis._lang("t_lastMonth"),
        },
        {
          internal_name: "between_dates",
          display_name: globalThis._lang("t_betweenDates"),
        },
      ],
      currentTime: 0,
      filter_values: {
        startDate: new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        endDate: new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2"),
        selectedQueues: [],
      },
      selected_date_filter_type: "today",
      test: false,
      swiperOptions: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,

        },
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '',
          clickable: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
      wavesurfer: null,
      messages: [
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "sdasd",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:24:07",
          "customer_id": null,
          "id": "197242d0-7cd0-41fd-854b-41770eda7bf9",
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": null,
          "sent_at": null,
          "source": "chat",
          "type": "reply",
          "user": {
            "archived_at": null,
            "created_at": "2023-08-12T09:46:36",
            "disabled_at": null,
            "email": "ersever@voyce",
            "id": 4,
            "object": "user",
            "role": "user"
          },
          "user_id": 4
        },
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "Merhaba ben müşteri temsilciniz ersever@voyce nasıl yardımcı olabilirim.",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:23:20",
          "customer_id": null,
          "id": "2aae90c2-3730-418b-9c73-6224821c6f3b",
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": "2023-08-16T08:23:27Z",
          "sent_at": null,
          "source": "chat",
          "type": "reply",
          "user": {
            "archived_at": null,
            "created_at": "2023-08-12T09:46:36",
            "disabled_at": null,
            "email": "ersever@voyce",
            "id": 4,
            "object": "user",
            "role": "user"
          },
          "user_id": 4
        },
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "test",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:23:42",
          "customer_id": null,
          "id": "bf3e5095-e371-4724-8f0a-48f78e8ad296",
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": null,
          "sent_at": null,
          "source": "chat",
          "type": "reply",
          "user": {
            "archived_at": null,
            "created_at": "2023-08-12T09:46:36",
            "disabled_at": null,
            "email": "ersever@voyce",
            "id": 4,
            "object": "user",
            "role": "user"
          },
          "user_id": 4
        },
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "selam",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:22:57",
          "customer": {
            "browser": "Chrome",
            "company_id": null,
            "created_at": "2023-08-11T09:44:52",
            "current_url": "https://voyce.com.tr/",
            "email": null,
            "external_id": null,
            "host": "voyce.com.tr",
            "id": "3538adfb-16fb-41d3-931e-7e97104c3e6e",
            "metadata": {
              "domain": "voyce",
              "queue": "voyce_queue_yazili_kanal"
            },
            "name": null,
            "object": "customer",
            "os": "Windows",
            "pathname": "/",
            "phone": null,
            "profile_photo_url": null,
            "title": "Anonymous User",
            "updated_at": "2023-08-16T08:22:59"
          },
          "customer_id": "3538adfb-16fb-41d3-931e-7e97104c3e6e",
          "id": "56648bfc-ef08-4c25-9531-9db35523d272",
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": null,
          "sent_at": "2023-08-16T08:22:56Z",
          "source": "chat",
          "type": "reply",
          "user": null,
          "user_id": null
        },
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "Birazdan müşteri temsilcinize bağlanacaksınız. Beklediğiniz için teşekkür ederiz.",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:22:58",
          "customer_id": null,
          "id": "2b68c20a-a4bd-46a1-8984-b9dd3ab7760d",
          "metadata": null,
          "object": "message",
          "private": false,
          "seen_at": "2023-08-16T08:23:27Z",
          "sent_at": null,
          "source": "chat",
          "type": "reply",
          "user": {
            "archived_at": null,
            "created_at": "2023-08-11T09:00:40",
            "disabled_at": null,
            "display_name": null,
            "email": "burak.tutkun@voyce.com.tr",
            "full_name": null,
            "id": 1,
            "object": "user",
            "profile_photo_url": null,
            "role": "admin"
          },
          "user_id": 1
        },
        {
          "account_id": "7f18d703-cbdb-4c2f-9c29-37e019289644",
          "attachments": [],
          "body": "asd",
          "content_type": "text",
          "conversation_id": "447db1d7-f776-43d2-97b2-ffc3600db42f",
          "created_at": "2023-08-16T08:23:48",
          "customer_id": null,
          "id": "9bbf1c00-6e00-4ad2-83ef-14e8404d61fd",
          "metadata": null,
          "object": "message",
          "private": true,
          "seen_at": null,
          "sent_at": null,
          "source": "chat",
          "type": "note",
          "user": {
            "archived_at": null,
            "created_at": "2023-08-12T09:46:36",
            "disabled_at": null,
            "email": "ersever@voyce",
            "id": 4,
            "object": "user",
            "role": "user"
          },
          "user_id": 4
        }
      ],
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'client', sortable: true },
        { key: 'total', sortable: true, formatter: val => `$${val}` },
        { key: 'issuedDate', sortable: true },
        { key: 'balance', sortable: true },
        { key: 'actions' },
      ],
      invoices: [

        {
          id: 5003,
          issuedDate: '30 Dec 2019',
          client: {
            address: '668 Robert Flats',
            company: 'Russell-Abbott Ltd',
            companyEmail: 'scott96@mejia.net',
            country: 'Congo',
            contact: '(254) 399-4728',
            name: 'Alan Jimenez',
          },
          service: 'Unlimited Extended License',
          total: 4372,
          avatar: '',
          invoiceStatus: 'Sent',
          balance: '-$344',
          dueDate: '17 Sep 2019',
        },
        {
          id: 5004,
          issuedDate: '27 May 2019',
          client: {
            address: '55642 Chang Extensions Suite 373',
            company: 'Williams LLC Inc',
            companyEmail: 'cramirez@ross-bass.biz',
            country: 'Saint Pierre and Miquelon',
            contact: '(648) 500-4338',
            name: 'Jennifer Morris',
          },
          service: 'Template Customization',
          total: 3198,
          invoiceStatus: 'Partial Payment',
          balance: '-$253',
          dueDate: '16 Aug 2019',
        },
        {
          id: 5005,
          issuedDate: '30 Jul 2019',
          client: {
            address: '56694 Eric Orchard',
            company: 'Hudson, Bell and Phillips PLC',
            companyEmail: 'arielberg@wolfe-smith.com',
            country: 'Uruguay',
            contact: '(896) 544-3796',
            name: 'Timothy Stevenson',
          },
          service: 'Unlimited Extended License',
          total: 5293,
          avatar: '',
          invoiceStatus: 'Past Due',
          balance: 0,
          dueDate: '01 Aug 2019',
        },
        {
          id: 5006,
          issuedDate: '10 Jun 2019',
          client: {
            address: '3727 Emma Island Suite 879',
            company: 'Berry, Gonzalez and Heath Inc',
            companyEmail: 'yrobinson@nichols.com',
            country: 'Israel',
            contact: '(236) 784-5142',
            name: 'Erik Hayden',
          },
          service: 'Template Customization',
          total: 5612,
          invoiceStatus: 'Downloaded',
          balance: '$883',
          dueDate: '12 Apr 2019',
        },
        {
          id: 5007,
          issuedDate: '01 Feb 2020',
          client: {
            address: '953 Miller Common Suite 580',
            company: 'Martinez, Fuller and Chavez and Sons',
            companyEmail: 'tatejennifer@allen.net',
            country: 'Cook Islands',
            contact: '(436) 717-2419',
            name: 'Katherine Kennedy',
          },
          service: 'Software Development',
          total: 2230,
          invoiceStatus: 'Sent',
          balance: 0,
          dueDate: '19 Nov 2019',
        },
        {
          id: 5008,
          issuedDate: '22 Mar 2020',
          client: {
            address: '808 Sullivan Street Apt. 135',
            company: 'Wilson and Sons LLC',
            companyEmail: 'gdurham@lee.com',
            country: 'Nepal',
            contact: '(489) 946-3041',
            name: 'Monica Fuller',
          },
          service: 'Unlimited Extended License',
          total: 2032,
          invoiceStatus: 'Partial Payment',
          balance: 0,
          dueDate: '30 Nov 2019',
        },
        {
          id: 5009,
          issuedDate: '30 Nov 2019',
          client: {
            address: '25135 Christopher Creek',
            company: 'Hawkins, Johnston and Mcguire PLC',
            companyEmail: 'jenny96@lawrence-thompson.com',
            country: 'Kiribati',
            contact: '(274) 246-3725',
            name: 'Stacey Carter',
          },
          service: 'UI/UX Design & Development',
          total: 3128,
          invoiceStatus: 'Paid',
          balance: 0,
          dueDate: '10 Sep 2019',
        },
        {
          id: 5010,
          issuedDate: '06 Jan 2020',
          client: {
            address: '81285 Rebecca Estates Suite 046',
            company: 'Huynh-Mills and Sons',
            companyEmail: 'jgutierrez@jackson.com',
            country: 'Swaziland',
            contact: '(258) 211-5970',
            name: 'Chad Davis',
          },
          service: 'Software Development',
          total: 2060,
          invoiceStatus: 'Downloaded',
          balance: 0,
          dueDate: '08 Dec 2019',
        },
        {
          id: 5011,
          issuedDate: '01 Jun 2019',
          client: {
            address: '3102 Briggs Dale Suite 118',
            company: 'Jones-Cooley and Sons',
            companyEmail: 'hunter14@jones.com',
            country: 'Congo',
            contact: '(593) 965-4100',
            name: 'Chris Reyes',
          },
          service: 'UI/UX Design & Development',
          total: 4077,
          avatar: '',
          invoiceStatus: 'Draft',
          balance: 0,
          dueDate: '01 Feb 2020',
        },
        {
          id: 5012,
          issuedDate: '30 Oct 2019',
          client: {
            address: '811 Jill Skyway',
            company: 'Jones PLC Ltd',
            companyEmail: 'pricetodd@johnson-jenkins.com',
            country: 'Brazil',
            contact: '(585) 829-2603',
            name: 'Laurie Summers',
          },
          service: 'Template Customization',
          total: 2872,
          invoiceStatus: 'Partial Payment',
          balance: 0,
          dueDate: '18 Oct 2019',
        },
        {
          id: 5013,
          issuedDate: '05 Feb 2020',
          client: {
            address: '2223 Brandon Inlet Suite 597',
            company: 'Jordan, Gomez and Ross Group',
            companyEmail: 'perrydavid@chapman-rogers.com',
            country: 'Congo',
            contact: '(527) 351-5517',
            name: 'Lindsay Wilson',
          },
          service: 'Software Development',
          total: 3740,
          invoiceStatus: 'Draft',
          balance: 0,
          dueDate: '01 Nov 2019',
        },

      ],
      chartOptionsComputedData: [
        [
          {
            "name": "data",
            "data": [
              76,
              35,
              78,
              11,
              42,
              64,
              86
            ]
          }
        ],
        [
          {
            "name": "data1",
            "data": [
              9,
              7,
              96,
              74,
              54,
              47,
              37
            ]
          }
        ],
        [
          {
            "name": "data2",
            "data": [
              82,
              81,
              76,
              73,
              62,
              38,
              12
            ]
          }
        ],
        [
          {
            "name": "data3",
            "data": [
              32,
              96,
              80,
              73,
              39,
              88,
              40
            ]
          }
        ],
        [
          {
            "name": "data4",
            "data": [
              56,
              16,
              82,
              92,
              33,
              14,
              24
            ]
          }
        ],
        [
          {
            "name": "data5",
            "data": [
              52,
              39,
              62,
              1,
              42,
              32,
              92
            ]
          }
        ]
      ],

      chartOptionsComputed: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2.5,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 0.9,
            opacityFrom: 0.5,
            opacityTo: 0.2,
            stops: [0, 80, 100],
          },
        },
        xaxis: {
          type: 'numeric',
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: { show: false },
        },
        yaxis: [
          {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
              left: 0,
              right: 0,
            },
          },
        ],
        tooltip: {
          x: { show: false },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: $themeColors.secondary,
            shadeTo: 'light',
            shadeIntensity: 0.65,
          },
        },
      },
      statisticsItems: [
        {
          icon: 'MessageCircleIcon',
          color: 'light-warning',
          title: '% 56',
          subtitle: 'Üst Üste Konuşma Oranı',
          customClass: 'mb-2 mb-xl-0',
        },
        // {
        //   icon: 'UserIcon',
        //   color: 'light-info',
        //   title: '8.549k',
        //   subtitle: 'Customers',
        //   customClass: 'mb-2 mb-xl-0',
        // },
        {
          icon: 'MoreHorizontalIcon',
          color: 'light-success',
          title: '% 12',
          subtitle: 'Sessizlik Oranı',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'XOctagonIcon',
          color: 'light-danger',
          title: '1249',
          subtitle: 'Temsilci Söz Kesme Sayısı',
          customClass: '',
        },
      ],
      projects: [],
      queues: [],
      selected_queue_group: [],
      queue_groups: [],
      selected_queues: [],
      selected_project: "",
      visible_users: [],
      finish_codes: [],
      dashboard_data: [],
      bar_charts: [],
      full_screen: false,
      fields: [
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true },
        {
          key: "Productivity",
          label: globalThis._lang("t_productivity"),
          sortable: true,
        },
        {
          key: "Inbound",
          label: globalThis._lang("t_inbound"),
          sortable: true,
        },
        {
          key: "Outbound",
          label: globalThis._lang("t_outbound"),
          sortable: true,
        },
        { key: "Dialer", label: globalThis._lang("t_dialer"), sortable: true },
        {
          key: "MissedCalls",
          label: globalThis._lang("t_missedCall"),
          sortable: true,
        },
        // { key: 'AvgCallTime', label: globalThis._lang('t_avgCallTime'), sortable: true },
      ],
      tableData: [],
      searchQuery: "",
      interval: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    load_sound() {
      this.test = !this.test;
      if (this.test) {
        this.wavesurfer = WaveSurfer.create({

          container: '#waveform',
          waveColor: '#F0F0F1',
          progressColor: $themeColors.primary,
          // url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
          url: 'https://recordings.wrtcdev.voyce.cc/recordings/2023/08/19/voyce/out-1692430598.369-5445226155.ogg',
          // splitChannels: [
          //   {
          //     waveColor: 'rgb(200, 0, 200)',
          //     progressColor: 'rgb(100, 0, 100)',
          //   },
          //   {
          //     waveColor: 'rgb(0, 200, 200)',
          //     progressColor: 'rgb(0, 100, 100)',
          //   },
          // ],
          // Set a bar width
          "height": 60,

          barWidth: 4,
          // Optionally, specify the spacing between bars
          barGap: 3,
          // And the bar radius
          barRadius: 8,
        })
        console.log("wavesurfer", this.wavesurfer)

        this.wavesurfer.once('interaction', () => {
          console.log("interaction")
          this.wavesurfer.play()
        })


        this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);
      } else {
        var myDiv = document.getElementById("waveform");
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
      }
    },
    date_filter_type_changed() {
      if (this.selected_date_filter_type == "today") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "yesterday") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_7_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          7 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_30_days") {
        this.filter_values.startDate = new Date(
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() +
          3 * 60 * 60 * 1000 -
          30 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(new Date().setHours(23, 59, 59, 0)).getTime() +
          3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "this_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
      if (this.selected_date_filter_type == "last_month") {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        this.filter_values.startDate = new Date(
          new Date(firstDay).setHours(0, 0, 0, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
        this.filter_values.endDate = new Date(
          new Date(lastDay).setHours(23, 59, 59, 0) + 3 * 60 * 60 * 1000
        )
          .toISOString()
          .replace(/([^T]+)T([^\.]+).*/g, "$1 $2");
      }
    },
    formatSeconds(seconds) {
      var minutes = Math.floor(seconds / 60);
      var remainingSeconds = seconds % 60;

      // Pad the minutes and seconds with leading zeros, if required
      var paddedMinutes = String(minutes).padStart(1, "0");
      var paddedSeconds = String(remainingSeconds).padStart(1, "0");

      if (minutes > 0) {
        return `${paddedMinutes} min ${paddedSeconds} sec`;
      } else {
        return `${paddedSeconds} sec`;
      }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      this.get_live_agent();
    },

    get_live_agent: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;

      this.dashboard_data = {};
      let _avg_productivity = [];

      for (const agent of response.filter(
        (e) => e != null && e.Queues.Value.length > 0
      )) {
        for (const queue of agent.Queues.Value) {
          if (
            this.selected_queues.includes(queue) &&
            this.selected_queues.length > 0
          ) {
            _avg_productivity.push(agent.Productivity.calculated ?? 0);
            if (this.visible_users[agent.Username + "@" + agent.Domain]) {
              if (!this.dashboard_data.hasOwnProperty(agent.Username)) {
                this.dashboard_data[agent.Username] = {
                  Agent: agent.Username,
                  Productivity: agent.Productivity.calculated || 0,
                  Inbound: agent.AcceptedCalls,
                  Outbound: agent.SuccessfulOutboundCalls,
                  Dialer: agent.AcceptedDialerCalls,
                  MissedCalls: agent.MissedDialerCalls + agent.MissedCalls,
                };
              }
            }
            break;
          }
        }

        // for (const agent of response.filter(e => e.Reason != 'Offline')) {
      }
      let sum = _avg_productivity.reduce(
        (total, currentValue) => total + currentValue,
        0
      );

      console.log("_avg_productivity", _avg_productivity);
      this.avg_productivity = [Math.round(sum / _avg_productivity.length)];
      this.tableData = Object.values(this.dashboard_data);
      // console.log("DATA", Object.values(this.dashboard_data));
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter((e) => e.internal_name != item.internal_name);
        }
      }
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
  },
  mounted: async function () {
    let _self = this;
    // Create the waveform

    this.finish_codes = globalThis.finish_codes;
    await this.get_users();

    await this.GetQueues();

    await this.GetQueueGroup();
    // await this.GetProjects();
    // await this.get_live_agent();

    // await this.GetDashboardData();

    // this.interval = setInterval(() => {
    //   _self.get_inbound_statistics();
    //   _self.get_live_agent();
    // }, 60000);
  },
  beforeDestroy() {
    // localStorage.setItem("dashboardData", JSON.stringify(this.$data));

    // clearInterval(this.interval);
  },
};
</script>

<style>
/* @import "@core/scss/vue/libs/vue-flatpicker.scss"; */
#waveform {
  cursor: pointer;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}
</style>
